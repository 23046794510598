import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const refractiveIndexChangeDetector = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    if (configuration.namedSpotRanges.length === 0) {
        throw new ValidationError("nameSpotRanges is empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "RefractiveIndexChangeDetector", {
        threshold: ["number", (value: any) => []],
        spotRange: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!configuration.namedSpotRanges.some((range) => range.name === value) &&
                !configuration.channelConfiguration.some((range) => range.name === value)) {
                errors.push(new ValidationError(`spotRange or channel "${value}" does not exist`))
            }
            return errors
        }],
        method: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!["average", "snr"].includes(value)) {
                errors.push(new ValidationError(`method "${value}" not recognised`))
            }
            return errors
        }, { optional: true }],
        referenceFrames: ["number", (value: any) => [], { optional: true }],
        gapFrames: ["number", (value: any) => [], { optional: true }],
        currentFrames: ["number", (value: any) => [], { optional: true }],
        noiseThreshold: ["number", (value: any) => [], { optional: true }]
    })

    return errors
}
