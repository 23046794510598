import { Configuration } from "../../../types/Configuration";
import { ParameterError, ValidationError } from "../ValidationError";

type RoutineParameters = { [key: string]: [any, (value: any) => ValidationError[], { optional: Boolean }?]; }

/**
 * Validates all matching routines in a configuration according to a predefined validator map.
 * 
 * Validations this function performs:
 * - If the parameter in the configuration has an invalid type not specified in the validator map
 * - If the parameter is missing from the configuration
 * - If the parameter is unknown (not defined in the validator map)
 * - If the parameter fails the custom validator function defined in the validator map
 * @param configuration The configuration.
 * @param routineName The name of the routine to match.
 * @param parameters A map of parameter names to their types and a validator function defined by you.
 * @returns The validation errors discovered.
 * @throws ValidationError When key validations about the configuration fail.
 * @throws SyntaxError Validator map is incorrectly defined.
 */
export const validateRoutine = (configuration: Configuration, routineName: string, parameters: RoutineParameters): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = []

    configuration.assaySequence.forEach(sequence => {
        sequence.routines?.forEach(routine => {
            if (routine.routineName === routineName) {
                // find all required (non-optional) parameters from the routine flags (third field)
                const expectedParameters = Object.entries(parameters).filter(([p, v]) => !v[2]?.optional).map(([p, v]) => p)
                const objectParameters = routine.parameters ? Object.keys(routine.parameters) : []

                expectedParameters.forEach(parameter => {
                    if (!objectParameters.includes(parameter)) {
                        errors.push(new ParameterError(
                            `Sequence "${sequence.name}" with routine "${routineName}" is missing "${parameter}" parameter`
                        ));
                    }
                })

                objectParameters.forEach(key => {
                    if (parameters[key] === undefined) {
                        errors.push(new ParameterError(
                            `Sequence "${sequence.name}" with routine "${routineName}" has an unknown parameter "${key}"`
                        ));
                    } else {
                        const [type, validator] = parameters[key];

                        if (type === null || type === undefined) {
                            throw new SyntaxError("Parameter validator type cannot be null or undefined")
                        } else if (
                            typeof routine.parameters[key] !== type &&
                            !(type === "array" && Array.isArray(routine.parameters[key]))
                        ) {
                            errors.push(new ParameterError(
                                `Sequence "${sequence.name}" with routine "${routineName}" parameter "${key}": parameter is not a ${type}`
                            ));
                        } else {
                            const nestedErrors = validator(routine.parameters[key]).map(error => {
                                error.message = `Sequence "${sequence.name}" with routine "${routineName}" parameter "${key}": ${error.message}`
                                return error
                            })
                            errors.push(...nestedErrors);
                        }
                    }
                })
            }
        })
    })

    return errors
}
